//==============================
// Generate portfolio item from Film_List
// ==============================
var Generate_Portfolio_Item = function ( _Name ) {

    var Images_HTML = "";
    var Actors_HTML = "";
    var Quote_HTML = "";
    var Awards_HTML = "";
    var Director_HTML = "";

    var Trailer_HTML = "";
    var Clip_HTML = "";
    var PDF_HTML = "";



    // Generate first Image from swipe
    // = for optimization we only add the first image here, and later in Portfolio-swiper.js we add the other images once the first has been loaded
    // Images_HTML = Images_HTML +
    // `<div class="swiper-slide">
    //     <div class="inner">
    //         <img
    //             src="./images/Portfolio_Pics/`+Picture_Resolution+`/`+_Name+`_`+1
    //             + `.jpg" alt="` + _Name + `" />
    //     </div>
    // </div>`;

    // Generate Images HTML

        // First Image is appened to be loaded first
        Images_HTML = Images_HTML +
        `<div class="swiper-slide">
            <div class="inner">
                <img
                    src="./images/Portfolio_Pics/`+Picture_Resolution+`/`+_Name+`_1`
                    + `.jpg" alt="Nilton Martins in ` + _Name + `" />
            </div>
        </div>`;

        // For the others, only the swipe is appened, and later the images in Portfolio-swiper.js
        for (var i = 2; i < Film_List[_Name].image_number+1; i++) {
            Images_HTML = Images_HTML +
                `<div class="swiper-slide">
                    <div class="inner">
                    </div>
                </div>`;
        }

    // Generate Images from array (old version)
    // for (var i = 1; i < Film_List[_Name].image_number+1; i++) {
    //
    //     Images_HTML = Images_HTML +
    //         `<div class="swiper-slide">
    //             <div class="inner">
    //                 <img
    //                     src="./images/Portfolio_Pics/`+Picture_Resolution+`/`+_Name+`_`+i
    //                     + `.jpg" alt="` + _Name + `" />
    //             </div>
    //         </div>`;
    //
    // }

    if ( Film_List[_Name].director.length > 0 ) {
        Director_HTML = `<div class="row-fluid">
        	<span class="span2 desc"> Dir: </span> <span class="span10">`
            + Film_List[_Name].director
            + `</span>
        </div>`;
    }

    //  Actors
    if ( "actors" in Film_List[_Name] ) {
        Actors_HTML = Actors_HTML +
        `<div class="row-fluid">
            <span class="span2 desc"> Cast: </span>
            <span class="span10">
                <ul class="actors">`;
        for (var i = 0; i < Film_List[_Name].actors.length; i++) {
            Actors_HTML = Actors_HTML + "<li>"+Film_List[_Name].actors[i]+"</li>";
        }
        Actors_HTML = Actors_HTML + "</ul>  </span>  </div>";
    }

    // Generate awards from array
    for (var i = 0; i < Film_List[_Name].award_name.length; i++) {
        Awards_HTML = Awards_HTML + `
        <li>
            <span class="award_name">`+
                Film_List[_Name].award_name[i]
            +`</span>
            <span>&ndash; `+ Film_List[_Name].award_place[i] +`</span>
        </li>`;
    }

    // If clip_link available
    if ( "clip_link" in Film_List[_Name] ) {
        Clip_HTML = `<div class="launch">
        	<a class="btn lightbox iframe" href="`+ Film_List[_Name].clip_link +`" title="`+ Film_List[_Name].title +`">Watch Clip</a>
        </div>`;
    }

    // If pdf_link available
    // if ( typeof(Film_List[_Name].pdf_link) !== "undefined" ) {
    if ( "pdf_link" in Film_List[_Name] ) {
        PDF_HTML = `<div class="launch">
        	<a class="btn"  href="documents/`+ Film_List[_Name].pdf_link +`" target="_blank" download> &nbsp;Read Article</a>
        </div>`;
    }

    // If trailer_link available
    // if ( Film_List[_Name].trailer_link.length > 5 ) {
    if ( "trailer_link" in Film_List[_Name] ) {
        Trailer_HTML = `<div class="launch">
        	<a class="btn lightbox iframe" href="`+ Film_List[_Name].trailer_link +`" title="Trailer">Watch Trailer</a>
        </div>`;
    }

    // If quote available
    // if ( Film_List[_Name].quote.length > 5 ) {
    if ( "quote" in Film_List[_Name] ) {
        Quote_HTML = `
        <div class="recommendation">
		    <div class="block-text rel zmin">
				<p>`+ Film_List[_Name].quote +`
                </p>
            </div>
			<div class="person-text rel">`
                + Film_List[_Name].quoted_person +`<span>`
                + Film_List[_Name].quoted_position +`</span>
			</div>
		</div>`;
    }

    // return of mult-line string, has to start with backtip `
    return `
    <div class="portfolio-single container">
            <div class="row">


                <!-- TITLE - column 8/12 -->
                <div class="span8 portfolio-field portfolio-title">
                    <h2>`+ Film_List[_Name].title +`</h2>
                </div>
                <!-- TITLE - column 8/12 -->


                <!-- PORTFOLIO-NAV - column 4/12 -->
                <div class="span4 portfolio-field portfolio-nav">
                    <a class="close_btn" href="#!/portfolio">
                        <div class="icon button back"></div>
                    </a>
                </div>
                <!-- PORTFOLIO-NAV - column 4/12 -->

            </div>

            <div class="row swiper_portfolio_row">

                <!-- PORTFOLIO-IMAGES - column 8/12 -->
                <div class="image_placeholder span8">

                 	<!-- SWIPER -->
                	<div class="swiper-container">
    					<div class="swiper-wrapper">` +
                            Images_HTML
    					+ `</div>
    				</div>
    				<!-- SWIPER END -->

    				<div class="controls">

    					<div class="pagination"></div>

    				    <div class="arrow-left" style="opacity: 0;">
    				    	<a class="icon-dash-left" href="#"></a>
    				    </div>
    				    <div class="arrow-right">
    				    	<a class="icon-dash-right" href="#"></a>
    				    </div>

    				</div>

                </div>
                <!-- PORTFOLIO-IMAGES - column 8/12 -->


                <!-- PORTFOLIO SIDEBAR - column 4/12 -->
                <div class="span4 portfolio-field desc-field">

                    <p class="synopsis">`
                    + Film_List[_Name].synopsis
                    + `</p>

                    <div class="film_info container-fluid">`

                        + Director_HTML
                        + Actors_HTML
    		            +

                        `<div class="row-fluid">
    	                	<span class="span2 desc"> Prod.: </span> <span class="span10">
                                <a href="`+ Film_List[_Name].production_link +`" target="_blank"> `+ Film_List[_Name].production_company +` </a>
                            </span>
    	                </div>
                    </div>`

                    + Quote_HTML

                    + ( Awards_HTML.length > 0 ? `<h3>Awards</h3>` : "" ) + `
                    <ul class="awards">` +
                        Awards_HTML
                    + `</ul>`

                    + Trailer_HTML

                    + Clip_HTML

                    + PDF_HTML
                + `</div>


            </div>

        </div>`;

};
