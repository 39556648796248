(function ($) {

	$(function() {

		var contactForm = $( '#contact-form, #website-contact-form' );
		var loader = $('#email_status');
		var transfer_status;
		var success = false;
		var Check_Server_NoResponse_Timer;

		function hide_Loader () {
	        setTimeout(function() {
	            loader.removeClass("show")
	        }, 2000 );
		}

		function display_Error () {
			loader.addClass("error");
			transfer_status.addClass("show error");
			hide_Loader();
		};

		function Start_Server_NoResponse_Timer() {
	        Check_Server_NoResponse_Timer = setTimeout(function() {
	            if ( success === false ) {
					display_Error();
				}
	        }, 7000 );
		}

		contactForm.submit(function()
		{
			// clear email status state
			$('#email_status').removeClass();

			transfer_status = $(this).siblings('#email_completion_status');

			if ($(this).valid())
			{
				loader.addClass("show");

				var formValues = $(this).serialize();

				// Timeout Error in case of no server response - so we don't show the loader indefintely
				Start_Server_NoResponse_Timer();

				transfer_status.removeClass();

				$.post($(this).attr('action'), formValues, function(data)
				{
					if ( data == 'success' )
					{
						clearTimeout(Check_Server_NoResponse_Timer); // stop server no response timer
						loader.addClass("success");

						transfer_status.addClass("show success");
						contactForm.clearForm();
						hide_Loader();
					}
					else
					{
						display_Error ()
					}
					// loader.delay(2000).fadeOut();
			        // setTimeout(function() {
			        //     loader.removeClass("show")
			        // }, 2000 );
				});
			}

			return false
		});

	});


	$.fn.clearForm = function() {
	  return this.each(function() {
	    var type = this.type, tag = this.tagName.toLowerCase();
	    if (tag == 'form')
	      return $(':input',this).clearForm();
	    if (type == 'text' || type == 'password' || tag == 'textarea')
	      this.value = '';
	    else if (type == 'checkbox' || type == 'radio')
	      this.checked = false;
	    else if (tag == 'select')
	      this.selectedIndex = -1;
	  });
	};

})(jQuery);
