var initSwiper;
var gallery;

$(function(){

	// made into a function so it can be called from .load complete (needs to be init after ajax call)
	initSwiper = function() {

		gallery = $('.swiper-container').swiper({
			watchActiveIndex: true,
			centeredSlides: true,
			pagination:'.pagination',
			paginationClickable: true,
			resizeReInit: true,
			keyboardControl: true,
			grabCursor: true,
			cssWidthAndHeight: "height", // or true or 'width' or 'height'
			calculateHeight: true,

			/* 0.5 because that's how arrows are set in css */
			onSlideChangeStart: function(){

			    // first slide
			    if (gallery.activeIndex==0) {
			      $('.arrow-left').css("opacity", "0");
			      $('.arrow-right').css("opacity", "1");
			    }
			    // last slide
			    else if (gallery.activeIndex == gallery.slides.length-1) {
			      $('.arrow-left').css("opacity", "1");
			      $('.arrow-right').css("opacity", "0");
			    }
			    // between
			    else {
			      $('.arrow-left').css("opacity", "1");
			      $('.arrow-right').css("opacity", "1");
			    }
			},

			/* no css fade version
			onSlideChangeStart: function(){

			    // first slide
			    if (gallery.activeIndex==0) {
			      $('.arrow-left').hide()
			      $('.arrow-right').show()
			    }
			    // last slide
			    else if (gallery.activeIndex == gallery.slides.length-1) {
			      $('.arrow-left').show()
			      $('.arrow-right').hide()
			    }
			    // between
			    else {
			      $('.arrow-left').show()
			      $('.arrow-right').show()
			    }
			},
	  		*/

			onImagesReady: function(){
				// changeSize()
				$(".pagination").addClass("animated fadeInUp");
				$(".arrow-right").addClass("animated fadeInUp");

				// remove classes again
				setTimeout( function () {
					$(".pagination").removeClass("animated fadeInUp");
					$(".arrow-right").removeClass("animated fadeInUp");
				}, 1000);
			}
		})


		//	function changeSize() {
		//		//Unset Width
		//		$('.swiper-slide').css('width','')
		//		//Get Size
		//		var imgWidth = $('.swiper-slide img').width();
		//		var container_width = $('#image_placeholder').width();
		//
		//		if (imgWidth+40>container_width) imgWidth = container_width-40;
		//		//Set Width
		//		$('.swiper-slide').css('width', imgWidth+40);
		//	}


		//Smart resize
		//	$(window).resize(function(){
		//		changeSize()
		//		gallery.resizeFix(true)
		//	})


		// Arrow Next Previous Click
		$('.arrow-left').on('click', function(e){
		    e.preventDefault()
		    gallery.swipePrev()
		})
		$('.arrow-right').on('click', function(e){
		    e.preventDefault()
		    gallery.swipeNext()
		})

		if ( gallery.slides.length < 2 ) {
			$('.arrow-left').css("display", "none");
			$('.arrow-right').css("display", "none");
		}



		// First image loaded, load the rest images
		$( ".swiper-slide .inner img" ).imagesLoaded(function() {

			var _Name = giveDetailUrl();

		    for (var i = 2; i < Film_List[_Name].image_number+1; i++) {

		        var Image_HTML = `<img
		                        src="./images/Portfolio_Pics/`+Picture_Resolution+`/`+_Name+`_`+i
		                        + `.jpg" alt="` + _Name + `" />
								`;

				// Array starts with 0, and also we don't want the first from i for loop
				// $(".swiper-slide .inner")[i-1] won't work because it returns a DOM element instead of a jquery object
				$($(".swiper-slide .inner")[i-1]).html(Image_HTML);

				// Example $($(".swiper-slide .inner")[i-1]) returns
				// [ <div class="inner"></div> ]
				// while $(".swiper-slide .inner")[i-1] returns
				// <div class="inner"></div> ( = no [] meaning no object )

		    }

	        // bind Hide Loading Indicator on ready images
	        $(".swiper-slide .inner img").each(function( _index, _curr_element ) {

	    		$( _curr_element ).imagesLoaded(function() {
	                $(this).parent().parent().addClass("loaded");
					gallery.reInit();
	    		});

	        });

		});


		// Old version: targeting all images (when all images loaded at once)
        // bind Hide Loading Indicator on ready images
        // $(".swiper-slide .inner img").each(function( _index, _curr_element ) {
		//
    	// 	$( _curr_element ).imagesLoaded(function() {
        //         $(this).parent().parent().addClass("loaded");
    	// 	});

	}

	// Init Swiper & Lightbox
	// only needed for local running, not server
	// setupLightbox();
	// initSwiper();

});
