// ==================
//      Browser Detection
// ==================

var Firefox_IE_Browser = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

// ==================
//      High Pixel Detection
// ==================

    var High_Pixel_Display = false;

    function isHighDensity() {
        return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 124dpi), only screen and (min-resolution: 1.3dppx), only screen and (min-resolution: 48.8dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (min-device-pixel-ratio: 1.3)').matches)) || (window.devicePixelRatio && window.devicePixelRatio > 1.3));
    }

    High_Pixel_Display = isHighDensity();

// ==================
//      Picture_Resolution
// ==================

    // Get max. screen width ( in case of mobile devices height (= width when in landscape mode) is bigger
    var Max_Screen_Width =  screen.height > screen.width ? screen.height : screen.width;
    var Max_Screen_Width_Resolution =  Max_Screen_Width;

    var Thumbnail_Resolution = "w_290";

    // double for High res displays
    if ( High_Pixel_Display ) {
        Max_Screen_Width_Resolution = Max_Screen_Width*2;
        Thumbnail_Resolution = "w_580";
    }

    var Picture_Resolution = "w_480";

    var Lightbox_Pic_Resolution = "w_480";

    function set_Picture_Resolution () {

        // Image Resolution Cascade
        if ( Max_Screen_Width_Resolution < 481 )
        {
            Picture_Resolution = "w_480";
            Lightbox_Pic_Resolution = "w_480";
        }
        else if ( Max_Screen_Width_Resolution-300 < 961 )
        {
            Picture_Resolution = "w_960";
            Lightbox_Pic_Resolution = "w_960";
        }
        else
        {
            Picture_Resolution = "w_1400";
            // 1400 makes no sense as Lightbox has max. width of about 600px
            Lightbox_Pic_Resolution = "w_960";
        }

        // 1920 makes only sense for retina/HDPI because max. portfolio size is 1000px
        if ( ( Max_Screen_Width >= 1024 ) &&  ( High_Pixel_Display ) )
        {
            Picture_Resolution = "w_1920";
            Lightbox_Pic_Resolution = "w_1920";
        }

    };

    set_Picture_Resolution();


// ===================
//   Page tags - originals ( to allow custom portfolio tags )
// ===================
var Page_Title_ORG = $("title").text();
var Page_Desc_ORG = $("meta[name=description]").attr( "content" );
var Page_Keywords_ORG = $("meta[name=keywords]").attr("content");
// og - open graph
var Page_og_title_ORG = $("meta[property='og:title']").attr("content");
var Page_og_desc_ORG = $("meta[property='og:description']").attr("content");
var Page_og_url_ORG = $("meta[property='og:url']").attr( "content" );
var Page_og_images_ORG = [];
// share images
for ( var i = 0; i< $("meta[property='og:image']").length ; i++) {
    Page_og_images_ORG.push( $("meta[property='og:image']")[i] );
}



// ===================
// Page tags - reset to originals
// ===================
function Page_meta_tags_reset_original() {
    $("title").html( Page_Title_ORG ); // or .text()
    $("meta[name=description]").attr( "content", Page_Desc_ORG );
    $("meta[name=keywords]").attr("content", Page_Keywords_ORG );
    // og - open graph
    $("meta[property='og:title']").attr("content", Page_og_title_ORG );
    $("meta[property='og:description']").attr("content", Page_og_desc_ORG );
    $("meta[property='og:url']").attr( "content", Page_og_url_ORG );
    // share images
    $("meta[property='og:image']").remove(); // deletes all share images
    for ( var i = 0; i< Page_og_images_ORG.length ; i++) {
        $("head").append( Page_og_images_ORG[i] ) ;
    }
}

// ===================
// Page tags - set different
// ===================
function Page_meta_tags_change( _Page_Title, _Page_Desc = Page_Desc_ORG, _Page_Keywords = Page_Keywords_ORG, _Page_og_title = Page_og_title_ORG, _Page_og_desc = Page_Desc_ORG, _Page_og_url = Page_og_url_ORG, _Page_og_images = Page_og_images_ORG ) { // "=" set a default for the function's var argument
    $("title").html( _Page_Title ); // or .text()
    $("meta[name=description]").attr( "content", _Page_Desc );
    $("meta[name=keywords]").attr("content", _Page_Keywords );
    // og - open graph
    $("meta[property='og:title']").attr("content", _Page_og_title );
    $("meta[property='og:description']").attr("content", _Page_og_desc );
    $("meta[property='og:url']").attr( "content", _Page_og_url );
    // share images
    $("meta[property='og:image']").remove(); // deletes all share images
    for ( var i = 0; i< Page_og_images_ORG.length ; i++) {
        $("head").append( _Page_og_images[i] ) ;
    }
}


// ==================
//      Helper Functions
// ==================

    // Find out if an element has one of multiple classes
    // function match_class ( _element, _classes ) {
    //
    //     $.each( _classes , function( _index, _curr_class ){
    //
    //         // alert( "Class " + $(_element) + ", Index #" + _index + ": " + _curr_class );
    //
    //         if ( $(_element).hasClass( _curr_class ) ) {
    //             return true;
    //         }
    //
    //     });
    //
    //     return false;
    //
    // };


// ==================
//      Preloaders
// ==================

    // Create empty PhotoSwipe array
    var Book_Gallery_Sources = [];

    // use "first" as tag for all that needs to be preloaded in .ready
    var load_Thumbnails = function ( _element_class ) {

        var Thumbnail_elements_to_load = $( _element_class );

        Thumbnail_elements_to_load.each(function( _index, _curr_element ) {

            // Link Name & alt name
            var _link_name = $(_curr_element).attr('data-filename');
            var _alt_name = _link_name;

            // Thumbnail
            var Thumbnail_Folder = "thumbnails";
            var p_Thumbnail_Resolution = Thumbnail_Resolution;

            var _thumb_filename = "images/"+Thumbnail_Folder+"/"+p_Thumbnail_Resolution+"/"+ _link_name +".jpg";

            // add default ajax class for project items
            var p_Additional_Parameters = " class='ajax' ";

            // get class
            var p_curr_element_class = $( _curr_element).attr("class");

            if ( p_curr_element_class.indexOf("book") > 0 )
            {
                // change to book / lightbox parameter
                p_Additional_Parameters =" class='lightbox' data-filename='" + _link_name + "' ";

                // var book_thumbnail_index = $(".portfolio-items .book").index(this);
                // console.log(book_thumbnail_index);

                // Add Source to Photoswipe Data Array
                // build Gallery array from Book_Responsive_Images array (otherwise photoswipe doesn't see it as a gallery with next / previous
                var Photo_Item = {
                    src: "images/Book/" +Picture_Resolution+ "/" + Book_Responsive_Images[0][_link_name][Picture_Resolution].src,
                    msrc: _thumb_filename,
                    h: Book_Responsive_Images[0][_link_name][Picture_Resolution].h,
                    w: Book_Responsive_Images[0][_link_name][Picture_Resolution].w
                };

                Book_Gallery_Sources.push(Photo_Item);
            }

            var Item_HTML = "\
            <img src=' "+_thumb_filename+" ' alt='" + _alt_name + " thumbnail'> <div class='mask'> \
                    <!-- lightbox - linked item -->\
                    <a href='" + _link_name + "' alt='" + _alt_name + " pic' " + p_Additional_Parameters + "></a>\
                    <!-- lightbox - linked item -->\
                </div>\
            ";


            $( _curr_element ).children(".media-box").append( Item_HTML );

    		$( _curr_element ).imagesLoaded(function() {
                $( _curr_element ).addClass("loaded");
                $container.isotope( 'reLayout' );
    		});

        });


    };


    // old lazy load images with empty img src tag
    // var lazyload_Images = function ( _elements, _folder ) {
    //     var Lazyload_Images = $( _elements );
    //
    //     Lazyload_Images.each(function( _index, _curr_element ) {
    //
    //         // find img and set src tag from data-lazyload-filename
    //         var _filename = $(_curr_element).attr('data-lazyload-filename');
    //         $( _curr_element ).attr( "src" , "images/"+_folder+"/"+_filename );
    //
    // 		$( _curr_element ).imagesLoaded(function() {
    //             // remove loading animation by adding "loaded" class
    //             $container.isotope( 'reLayout' );
    // 		});
    //
    //     });
    // };

    // first feed with .lazyImages-First ( portfolio first, resume page images FF & showreel )

    var Portfolio_Images_Loaded = false; // needed for resize event

    // preload Book Lightbox only images for main page
    // var preload_Images = function () {
    //
    //     var All_Preload_Images = $(".preload");
    //
    //     All_Preload_Images.each(function( _index, _element) {
    //
    //         var name = $(_element).attr('data-filename');
    //         $(_element).attr( "href" , "images/Book/"+Lightbox_Pic_Resolution+`/`+name+".jpg");
    //     });
    //
    // };


// ==================
//              Main
// ==================

    // chrome browser detection
    if( navigator.userAgent.toLowerCase().indexOf('chrome') > -1 ){
    	// add chrome class to body, to flip 3d x values on hover/click
        $("body").addClass("chrome-browser");
    }
    else if( navigator.userAgent.toLowerCase().indexOf('safari') > -1 ){
    	// safari browser detection to prevent "open" hover button glitch
        $("body").addClass("safari-browser");
    }

/* Version: 1.2 */

var animEngine = "";
var onAnimationFinished = false;

var safeMod = true;
var portfolioKeyword;
var $container;
var Touch_device = false;

/* DOM ready */
// was previously $(function() { but should be the same
$( document ).ready(function() {

	// ------------------------------
	// PORTFOLIO DETAILS
	// if url contains a portfolio detail url
	portfolioKeyword = $('.portfolio').attr('id');
	initialize();
	var detailUrl = giveDetailUrl();
	// ------------------------------
    // portfolio container (put here to avoid $container.width error)
	$container = $('.portfolio-items');

	if ( !Modernizr.smil ) {

		$('html').addClass('no-smil');

	}

	// ------------------------------
	// SET COVER IMAGE AS BG IMAGE
    // var c = 0;
	// $('.cover').each(function(index, element) {
    //     var _Pic_holder = $(this).find('.cover-image-holder img');
	// 	// $(this).css('background-image', 'url(' + _Pic_holder.attr('src') + ')');
    //
    //     _Pic_holder.imagesLoaded(function() {
    //         _Pic_holder.css("opacity","0");
	// 	});
    //
	// });
	// ------------------------------


	// ------------------------------
	// Upgrade to Book Mode if Desktop & hardware supported
	var isIE11 = !!navigator.userAgent.match(/Trident\/7\./);
	var BookMode = Modernizr.csstransforms && Modernizr.csstransforms3d && $(window).width() > 1179 && !$.browser.msie && !isIE11;

	if ( BookMode ) {

		$('html').removeClass('safe-mod');
        if ( Firefox_IE_Browser ) {
            $('html').addClass('Open_Book_z_index_BugFix');
        }
        safeMod = false;

	}

    else {
        // safe mode
        safeMod = true;

        try {
    		setActivePage();
    		$.address.change(function() {
    			setActivePage();
    		});
        }
        catch (e) {
            console.log(e);
            window.location.href = "#!/resume";
            window.location.reload();
        }

    }

    // show Movie_Showreel
	if( window.location.hash == "#!/resume?Movie_Showreel") {  // if hash tag doesnt exists - go to first page
        $(".demoreel.movie a").trigger("click");
        if ( !safeMod ) {
            $(".cover").trigger("click");
        }
	}

    // show Video_Showreel
	if( window.location.hash == "#!/resume?Video_Showreel") {  // if hash tag doesnt exists - go to first page
        $(".demoreel.video a").trigger("click");
        if ( !safeMod ) {
            $(".cover").trigger("click");
        }
	}


	// ------------------------------

	// ------------------------------
	// Old way: LAYOUT FALLBACK: SAFE MOD
	// safeMod = $('html').attr('data-safeMod') === 'true';
	// var isIE11 = !!navigator.userAgent.match(/Trident\/7\./);
	// safeMod = safeMod || !Modernizr.csstransforms || !Modernizr.csstransforms3d || $(window).width() < 1180 || $.browser.msie || isIE11;
	// if(safeMod) {
    //
	// 	$('html').addClass('safe-mod');
    //
	// 	setActivePage();
	// 	$.address.change(function() {
	// 		setActivePage();
	// 		});
	// }
	// ------------------------------

        // commented out because this will keep the mobile navigation from working when loading from /portfolio
	    // open the book if url contains #/portfolio
		// if (($.address.path().indexOf("/"+ portfolioKeyword)!=-1) && !safeMod) {
		// 	setTimeout(function() { openMenu() },500);
		// }

	// ------------------------------
	// ADAPT LAYOUT
	adaptLayout();
    $( window ).on( "resize orientationchange", function( event ) {
		adaptLayout();
	});
	// ------------------------------


	// ------------------------------
	// FULL BROWSER BACK BUTTON SUPPORT
	$.address.change(function() {
			var detailUrl = giveDetailUrl();
			if(detailUrl != -1 ) {
				showProjectDetails(detailUrl);
			} else {
				if ($.address.path().indexOf("/"+ portfolioKeyword)!=-1) {
					hideProjectDetails(true,false);
				}
			}

            // Google Analytics single page website to register which page is loaded
        	var path = $.address.path();
        	path = path.slice(1, path.length);
            ga('send', 'pageview', path );

		});
	// ------------------------------

	// Menu item clicked: Scroll page to top & reset filter bar
    // ^= means every link which starts with
	$(" li a[href^='#!/'] ").click(function(){
        $(".mobile_menu .filters").hide();
        mobile_menu_compact = false;
        Filter_Hidden = true;
        $("body").scrollTop(0);
	});


	// ----------------------------------------
	// MOBILE MENU & FIXED FILTER BAR
	// ----------------------------------------

    var Filter_position = $(".main .filters.normal").position().top;

    $( window ).on( "resize orientationchange", function( event ) {
		Filter_position = $(".main .filters.normal").position().top;
    });

    // window.scroll setup vars
    var curr_Top_offset = 0,
          prev_Top_offset = 0,
          Scrolling_down = 0,
          Scrolling_up = 0;

    var Menu_Top_Height = 70;
    var mobile_menu_compact = false;
    var Filter_Hidden = true;

    function get_Current_Scrolling_Offsets () {
        curr_Top_offset = $(document).scrollTop() || $(".rm-middle .antiscroll-inner").scrollTop();
        // console.log("document top: " + curr_Top_offset );
    };

    function set_Mobile_Menu_Height () {

        // Scrolling down
        if ( curr_Top_offset > prev_Top_offset ) {
            Scrolling_down ++;

            if ( ( mobile_menu_compact === false ) && ( curr_Top_offset >= Menu_Top_Height ) && ( ( Scrolling_down > 20 ) || ( curr_Top_offset > prev_Top_offset + 20 ) ) ) {
                $(".mobile_menu").addClass("compact");
                // console.log("menu compacted");
                // Update Filter Top position due to changed menu height
                Filter_position = $(".main .filters.normal").position().top;
                mobile_menu_compact = true;
            }

            Scrolling_up = 0;
        }

        // Scrolling up
        if ( curr_Top_offset < prev_Top_offset ) {
            Scrolling_up ++;
            // console.log(Scrolling_up);
            if ( ( mobile_menu_compact === true ) && ( ( Scrolling_up > 20 ) || ( curr_Top_offset < 75 ) || ( curr_Top_offset < prev_Top_offset - 20 ) ) ) {
                $(".mobile_menu").removeClass("compact");
                // console.log("menu expanded");
                Filter_position = $(".main .filters.normal").position().top-20;
                mobile_menu_compact = false;
            }

            Scrolling_down = 0;
        }

        prev_Top_offset = curr_Top_offset;
    };

    function set_sticky_Filter_Menu () {

        // Check if to show the filter bar
        // console.log("Filter_position: " +Filter_position);
        // console.log("Filter_Hidden: " + Filter_Hidden);
        if ( ( curr_Top_offset > Filter_position ) && ( Filter_Hidden === true ) ) {

            if ( ( safeMod ) && ( $.address.path() === "/portfolio") )
            {
                // $(".mobile_menu .filters.off").addClass("hidden");
                $(".mobile_menu .filters").show();
                Filter_Hidden = false;
            }

            // Book version of fixed filter
            else if ( safeMod === false ) {
                // $(".mobile_menu .filters.off").addClass("hidden");
                $(".fixed_book.filters").show();
                Filter_Hidden = false;
            }
        }

        if ( ( curr_Top_offset < Filter_position ) && ( Filter_Hidden === false ) ) {
            if ( safeMod )
            {
                $(".mobile_menu .filters").hide();
                Filter_Hidden = true;
            }
            else if ( safeMod === false ) {
                $(".fixed_book.filters").hide();
                Filter_Hidden = true;
            }
        }
    };

    $(window).scroll(function () {
        get_Current_Scrolling_Offsets();
        set_Mobile_Menu_Height ();
        set_sticky_Filter_Menu ();
    });

    $(".rm-middle .antiscroll-inner").scroll(function () {
        get_Current_Scrolling_Offsets();
        set_sticky_Filter_Menu ();
    });

	// ------------------------------
	// PORTFOLIO FILTERING - ISOTOPE

	// cache container
	$container.css("opacity", 0);

	// showLoader();

	// detect chrome for css3 flashing fix
	$.browser.chrome = /chrome/.test(navigator.userAgent.toLowerCase());
	if(!$.browser.chrome || safeMod) {
		$('html').addClass('no-chrome');
	}

	animEngine = $.browser.chrome && !safeMod ? 'jquery': 'best-available';

	if($container.length) {
		// $container.imagesLoaded(function() {

			// initialize isotope
			$container.isotope({
			  itemSelector: '.hentry',
			  layoutMode: 'fitRows',
			  animationEngine: animEngine,
              filter: '.first'
			});

			setMasonry();

            $( window ).on( "resize orientationchange", function( event ) {
				$container.css("opacity", "0");
				setTimeout(function() {
					setMasonry();
				}, 100);
			});

            function scroll_to_Top () {
                if ( safeMod ) {
                    // animated scroll to top
                    $("body").animate({
                        scrollTop: 0
                    }, 750);
                }
                else {
            		$('.antiscroll-inner').animate({
            		    scrollTop: 0
            		 }, 750);
                }
            }

			// filter items when filter link is clicked
			$('.filters a').click(function(e){
                e.preventDefault();
                var selector = $(this).attr('data-filter');
                setMasonry();
                $container.isotope({ filter: selector, animationEngine: animEngine });
                $(".filters li>a[data-filter='" + selector + "']").parent().addClass('current').siblings().removeClass('current');

                scroll_to_Top();

			});

			// on first: click "show all movies"
			$('.show_all_movies').click(function(event){
			  event.preventDefault();
			  $container.isotope({ filter: ".film", animationEngine: animEngine });
			  $('.filters [data-filter=".film"]').parent().addClass('current').siblings().removeClass('current');


        		// animated scroll to top
                scroll_to_Top();
			});

			// hide portfolio while still loading, after load fade-in
			// setTimeout(function() {
			// 	$container.addClass("animated fadeInUp");
			// 	$container.css("opacity", 1);
			// },860);


			// needed for resize event
			Portfolio_Images_Loaded = true;

			// hideLoader();

			// setTimeout(function() {
				// LIGHTBOX
				// setupLightbox();
				// ------------------------------
			// },500);

		// });
	}

	// ------------------------------

	//$(".portfolio-items").isotope( 'on', 'layoutComplete', function( isoInstance, laidOutItems ) {
	//} );

    function reload_Window() {
        // without Timeout it won't work on Firefox
        setTimeout(
            function(){
                location.reload(true);
            },
        10 );
    }

	// ------------------------------
	// SCROLLBARS
	if(!safeMod) {

        $( window ).on( "resize orientationchange", function( event ) {
			if($(window).width() < 1180) { /* was < 960 */
				reload_Window()
			}
        });

	}

	// RESIZE out of safe to normal mode
    $( window ).on( "resize orientationchange", function( event ) {
		if(safeMod) {
			// refreshScrollBars();
			if($(window).width() >= 1180) { /* was < 960 */
				reload_Window()
			}
		}

		// if ( Portfolio_Images_Loaded === true ) {
			// setupLightbox();
		// }
		// setupLightbox(); -> in conflict with on_load preloader -> What if user resites window before onload event, lightbox will fire to early and images can't be preloaded
	});
	// ------------------------------


	// ------------------------------
	// FIT TEXT
	// fitText();
	// ------------------------------

	// ------------------------------
	// SKILL BARS
	animateBars();
	// ------------------------------




	// ------------------------------
	// PORTFOLIO DETAILS

	// Show details
	$("a.ajax").live('click',function() {

        // Add not blocking loader to clicked item
        $(".loading_item_indicator").removeClass("loading_item_indicator");
        $(this).parent().addClass("loading_item_indicator");

		var returnVal;
		var url = $(this).attr('href');
		var baseUrl = $.address.baseURL();

		if(url.indexOf(baseUrl) != -1) { // full url
			var total = url.length;
			detailUrl = url.slice(baseUrl.length+1, total);
		} else { // relative url
			detailUrl = url;
		}

		$.address.path(portfolioKeyword + '/' + detailUrl );

		return false;

	});
	// ------------------------------


	// ------------------------------
	// 3D LAYOUT
	Menu.init();
	// ------------------------------


});


// ------------------------------
// Agent Info - show / hide

// filter items when filter link is clicked
var Agent_Info_hidden = true;

$('.agent').click(function(e){
    e.preventDefault();
    // toggle Agent Info
    if ( Agent_Info_hidden ) {
        $(".agent_info").fadeIn(250);
        Agent_Info_hidden = false;
        // $(".agent_info").addClass("show");
    }
    else {
        $(".agent_info").fadeOut(150);
        Agent_Info_hidden = true;
        // $(".agent_info").removeClass("show");
    }

	// // animated scroll to top
	// $('html, body').animate({
	//     scrollTop: 0
	//  }, 750);
});

$('.exit_credit_contact').click(function(e){
    e.preventDefault();
    $('html').removeClass("show_credits");
});

// ------------------------------

// ------------------------------
// CREDITS - show / hide

// filter items when filter link is clicked
$('.credit a').click(function(e){
    e.preventDefault();
    $('html').addClass("show_credits");

	// animated scroll to top
	$('html, body').animate({
	    scrollTop: 0
	 }, 750);
});

$('.exit_credit_contact').click(function(e){
    e.preventDefault();
    $('html').removeClass("show_credits");
});

// ------------------------------



// ----------------------------------------------
// iFrame / Movie Lightbox - show / hide
// ----------------------------------------------
$("a.lightbox.iframe").live('click',function(event) {
    // console.log( event.type );
    // console.log( event.target );
    event.preventDefault();

    if ( $(".Movie_Lightbox_Container").html() == "" ) {
        // Pace checks whether an element exists as DOM, not if it is loaded !
        // therefore we need to look for an item inside the iFrame to check if it is loaded
        Pace.options.elements.selectors = [".Movie_Lightbox_Container iframe body"];
        Pace.restart();

    	var iFrame_link = $(this).attr("href");
        $(".Movie_Lightbox_Container").html("<iframe frameborder='0' hspace='0' scrolling='auto' src='"+iFrame_link+"' </iframe>");

        $(this).parent().addClass("loading_item_indicator");

        $(".Movie_Lightbox_Container iframe").on("load", function(e) {
            setTimeout( function () {
                Pace.stop();
                $(".rm-container").addClass("rm-in rm-nodelay");
                $("html").addClass("Show_iFrame_Lightbox");
                $(".loading_item_indicator").removeClass("loading_item_indicator");
            }, 250);
        });
    }

});

    // sub lightbox functions
    $(".demoreel.movie").live('click',function(event) {

        // open on /Movie_Showreel
        // $.address.change( $.address.path() + "/Movie_Showreel" );
        // change tags;
        // in address.change()
        // if address has /Movie... simulate click on the item

        // reset tags on close

        // Page title
        var Page_Title = "Movie Showreel - Nilton Martins";

        // Keywords
        var Page_Keywords = "Movie Showreel, " + Page_Keywords_ORG;

        var Page_Reel_Desc = "Watch Nilton's Movie Showreel, featuring clips from feature films and shorts.";

        // og - open graph
        // var Page_Video_address = "#!Movie_Showreel";
        window.location.hash = "#!/resume?Movie_Showreel"; // update browser address field
        var Page_Reel_url = window.location.href;

        Page_meta_tags_change(
            Page_Title,
            Page_Reel_Desc,
            Page_Keywords,
            Page_Title,
            Page_Reel_Desc,
            Page_Reel_url,
            ['<meta property="og:image" content="/images/Movie_Showreel.jpg" />']
        );

        $(".Lightbox_Overlay").addClass("Showreel");

    });

    $(".demoreel.video").live('click',function(event) {

        // Page title
        var Page_Title = "Ad & Clip Showreel - Nilton Martins";

        // Keywords
        var Page_Keywords = "Ad & Clip Showreel, " + Page_Keywords_ORG;

        var Page_Reel_Desc = "Watch Nilton's Advertisement & Clip Showreel, featuring clips from recent TV & movie ads, as well a s music videos.";

        // og - open graph
        // var Page_Video_address = "#!Video_Showreel";
        window.location.hash = "#!/resume?Video_Showreel"; // update browser address field
        var Page_Reel_url = window.location.href;

        Page_meta_tags_change(
            Page_Title,
            Page_Reel_Desc,
            Page_Keywords,
            Page_Title,
            Page_Reel_Desc,
            Page_Reel_url,
            ['<meta property="og:image" content="/images/Video_Showreel.jpg" />']
        );

        $(".Lightbox_Overlay").addClass("Showreel");

    });

// Close Lightbox functions
$(".Lightbox_Background, .Lightbox_Overlay .close_btn").on('click',function(event) {
    $("html").removeClass("Show_iFrame_Lightbox");
    $(".rm-container").removeClass("rm-in");
    setTimeout( function () {
        $(".Movie_Lightbox_Container").empty();
    }, 550);
});

    // only if lightbox shows showreels, reset Page tags on quit
    $("body").on('click', ".Showreel.Lightbox_Overlay .Lightbox_Background, .Showreel.Lightbox_Overlay .close_btn", function(event) {
        // setTimeout( function () {
            Page_meta_tags_reset_original();
            window.location.hash = "#!/resume" // deletes the #!showreel address identifier
            $(".Showreel").removeClass("Showreel");
        // }, 150);
    });

$(".p-overlay").on("click", "a.lightbox.iframe", function(event) {
    $("body").addClass("Pace_z_index_in_Front");
    console.log("touchend Pace_z_index_in_Front");
});

// $("html").on('keyup', ".Lightbox_Overlay",function(event) {
//     if (e.keyCode == 27) { // escape key
//         alert();
//     }
// });

// ------------------------------



// expose Photoswipe to other elements / global environment
var Book_LightBox = 0;


// Show lightbox loading indicator
$(".portfolio-items a.lightbox").live('click',function(e) {

    e.preventDefault();

    // Clicked Book item Index
    var _Book_item_index = $(".portfolio-items a.lightbox").index(this);
    var _Book_item_Name = $(this).attr("data-filename");

    // $(".rm-container").addClass("rm-in");
    $(".rm-container").addClass("rm-in rm-nodelay");

    // =============================================
    // PhotoSwipe for Book items
    // =============================================

    var _Photoswipe = $('.pswp')[0];

    // define options (if needed)
    var options = {
        index: _Book_item_index, // start at first slide
        history: false,
        preload:[1,1],
        closeOnScroll: false,
        bgOpacity: 0.75,
        clickToCloseNonZoomable: false,
        // showHideOpacity: true,
        // dimensions for zoom effect
        getThumbBoundsFn: function(index) {

            // find thumbnail element
            var thumbnail = document.querySelectorAll('.portfolio-items .book .media-box img')[_Book_item_index];

            // get window scroll Y
            var pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
            // optionally get horizontal scroll

            // get position of element relative to viewport
            var rect = thumbnail.getBoundingClientRect();

            // w = width
            return {x:rect.left, y:rect.top + pageYScroll, w:rect.width};


            // Good guide on how to get element coordinates:
            // http://javascript.info/tutorial/coordinates
        }
    }

        // Initialize PhotoSwipe
        Book_LightBox = new PhotoSwipe( _Photoswipe, PhotoSwipeUI_Default, Book_Gallery_Sources, options);

        Book_LightBox.listen('gettingData', function(index, item) {
            item.src = Book_Gallery_Sources[index].src;
            item.h = Book_Gallery_Sources[index].h;
            item.w = Book_Gallery_Sources[index].w;
        });

        // Hide Download PopOver on item click
        $(".pswp__share-modal").on( "click", ".pswp__share-tooltip", function() {
            $(".pswp__share--download").removeClass("pswp__share-modal--hidden");
            $(".pswp__share--download").addClass("pswp__share-modal--fade-in");
        });

        // On close, fade out picture & image
        Book_LightBox.listen('close', function(index, item) {
            Book_LightBox.options.showHideOpacity = true;
            // slide out in middle
            Book_LightBox.options.getThumbBoundsFn = function(index) {

                // find thumbnail element
                var thumbnail = document.querySelectorAll('.portfolio-items .book .media-box img')[index];

                // get position of element relative to viewport
                var rect = thumbnail.getBoundingClientRect();

                // w = width
                return {x:window.width/2, y:window.height/2, w:rect.width};

            };

            $(".rm-container").removeClass("rm-in");
        });

        Book_LightBox.init();

        // Book_LightBox.invalidateCurrItems(); // reinit Items
        // Book_LightBox.updateSize(true); // reinit Items

});
// ------------------------------



// var Book_Cover_Images;
//
// function get_BackgroundImage ( _el ) {
//     // slice shaves off the url("...") from the background-image property
//     return _el.css("background-image").slice(4,-1);
// };


$( document ).ready(function() {

    // hint: High resolution of cover handled by css
    // var Book_Cover_Front = get_BackgroundImage( $(".rm-cover .cover") );
    // var Book_Cover_Back = get_BackgroundImage( $(".rm-right .cover") );
    //
    // Book_Cover_Images = $("<div class='Book_Cover_Images'><img src='" + Book_Cover_Front + "' /><img src='" + Book_Cover_Back + "' /></div>");
    //
    // console.log(Book_Cover_Front);
    // console.log(Book_Cover_Back);

    load_Thumbnails(".first.thumbnail");

});



// Document LOAD
// when everything's loaded
$( window ).load(function() {

	// $( window ).imagesLoaded( { background: ".cover" }, function() {

        // Checks if connection is fast enough for retina 2x image assets
        // "Connection_Timer_over" is init at the document head (index.html; so it starts right at the beginning) with a timer of 5 secs
        if ( ( Connection_Timer_over === true ) && ( High_Pixel_Display ) ) {

            console.log("Slow Connection - for performance only SD images are loaded, although HD display available");

            // user is on a slow connection because “Load Timer" timed out
            High_Pixel_Display = false;
            Thumbnail_Resolution = "w_290";

            // Check if phone or tablet / desktop
            if ( Max_Screen_Width < 1024 ) {
                Max_Screen_Width_Resolution = 480;
            }
            else
            {
                Max_Screen_Width_Resolution = 960;
            }

            // Max_Screen_Width_Resolution = Max_Screen_Width_Resolution / 2;

            set_Picture_Resolution();
        }
        else if ( High_Pixel_Display ) {
            console.log("Fast connection - using HD / retina version ");
        }



        // === Load remaining Thumbnails

        // Check if url is direct link to portfolio item or to main page
        if ( giveDetailUrl() === -1 ) {
            load_Thumbnails(".thumbnail:not(.first)");
        }
        else {
            // postpone thumbnail loading for a bit to let the browser load portfolio images first
            setTimeout( function() {
                load_Thumbnails(".thumbnail:not(.first)");
            } , 100 );
        }

        setMasonry();

        Pace.stop();

        $(".Page_Preload_ON").removeClass("Page_Preload_ON");

        // Testing only

        // var toggle = false;
        //
        // $('body').keyup(function(e){
        //     if((e.keyCode == 17) && (toggle === false)){
        //         toggle = true;
        //         $(".Page_Preload_ON").removeClass("Page_Preload_ON");
        //     }
        //     else if (e.keyCode == 17) {
        //         toggle = false;
        //         $("html").addClass("Page_Preload_ON");
        //     }
        // });

        // $(".Page_Preload_ON").removeClass("Page_Preload_ON");


        // Modernizer Touch-device test (https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js)
        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {

            Touch_device = true;

            // add Fastclick (via CDN)
            $.ajax({
              url: "http://cdnjs.cloudflare.com/ajax/libs/fastclick/1.0.6/fastclick.min.js",
              dataType: "script",
              context: document.body,
              cache: true
            }).done(function() {
                // console.log("fastclick is active");
                $( this ).addClass( "done" );
                FastClick.attach(document.body);
            })
            // local FastClick fallback
            .fail(function() {
                console.log("FastClick CDN failed. Loading FastClick.js from local server");
                $.ajax({
                  url: "js/static/fastclick.js",
                  dataType: "script",
                  context: document.body,
                  cache: true
                }).done(function() {
                    FastClick.attach(document.body);
                })
                .fail(function() {
                    console.log("FastClick.js local file not found");
                });
            });

        };

        // on website loaded, preload all lightbox images
        // preload_Images();
        // hide Page Loader
        // load other thumbnails

	// }); // ends imagesLoaded function

});

// WINDOW ONLOAD
window.onload = function() {

	// ie8 cover text invisible fix
	// if(jQuery.browser.version.substring(0, 2) == "8." || jQuery.browser.version.substring(0, 2) == "7.")
	// {
	// 	setTimeout(function() { setActivePage(); },2000);
	// }

	// setTimeout(function() { fitText(); },1000);

    // Scroll reveal
	// on scroll effects (only on small width)
	// if ( safeMod === true ) {
	// 	var on_scroll_animated_elements = $(".scroll_animation");
	// 	on_scroll_animated_elements.each(function(index, element) {
	// 		$(element).attr("data-sr","");
	// 	});
    //
    //     var config = {
    //             reset:  true,
    //             mobile: true
    //           }
    //
    //     window.sr = new scrollReveal( config );
    //
	// 	$(".content.mobile_fadeIn").addClass("animated fadeInUp");
	// }

    // Hide Loading Indicator on ready thumbnail images
    // Hide_Loaders_Indicators( $(".isotope-item img") );

};
// WINDOW ONLOAD

// WINDOW ONLOAD
// no change on ipad flip
// window.onorientationchange = function() {

//	location.reload(true);
//	adaptLayout();

// };
// WINDOW ONLOAD

// ------------------------------
// ------------------------------
	// FUNCTIONS
// ------------------------------
// ------------------------------


// ------------------------------
// INITIALIZE
var inAnimation, outAnimation, safeModPageInAnimation, cover_h1_tune, cover_h2_tune, cover_h3_tune, cover_h3_span_tune;
function initialize() {
	inAnimation = $('html').attr('data-inAnimation');
	outAnimation = $('html').attr('data-outAnimation');
	safeModPageInAnimation = $('html').attr('data-safeModPageInAnimation');
	// cover_h1_tune = $('html').attr('data-cover-h1-tune');
	// cover_h2_tune = $('html').attr('data-cover-h2-tune');
	// cover_h3_tune = $('html').attr('data-cover-h3-tune');
	// cover_h3_span_tune = $('html').attr('data-cover-h3-span-tune');
}
// ------------------------------



// ------------------------------
// ADAPT LAYOUT
function adaptLayout() {
	var width = safeMod ? $('body').width(): $('.content').width();
	if(width < 420) {
		$('html').addClass('w420');
	} else {
		$('html').removeClass('w420');
	}
}
// ------------------------------



// ------------------------------
// change the number of masonry columns based on the current container's width
function setMasonry() {

	var containerW = $container.width();
	var items = $container.children('.hentry');
	var columns, columnWidth;
	var viewports = [ {
			width: 1200,
			columns: 5
		}, {
			width: 900,
			columns: 4
		}, {
			width: 600,
			columns: 3
		}, {
			width: 311, // previously 320
			columns: 2
		}, {
			width: 0,
			columns: 2 // previously 1
		} ];

	for( var i = 0, len = viewports.length; i < len; ++i ) {

		var viewport = viewports[i];

		if( containerW > viewport.width ) {

			columns = viewport.columns;
			break;

		}
	}

	// set the widths (%) for each of item
	items.each(function(index, element) {
        // not used because x2 won't scale probably at width > 500
        // var multiplier = $(this).hasClass('x2') && columns > 1 ? 2: 1;
		var multiplier = $(this).hasClass('x2') && columns > 1 ? 1: 1;
		var itemWidth = (Math.floor( containerW / columns ) * 100 / containerW) * multiplier ;
		$(this).css( 'width', itemWidth + '%' );
	});

	columnWidth = Math.floor( containerW / columns );

	onAnimationFinished = function(){
        $container.css("opacity", "1");
        // $container.addClass("animated fadeInUp");
	};

	$container.isotope( 'reLayout', onAnimationFinished ).isotope( 'option', { fitRows: { columnWidth: columnWidth }, animationEngine: animEngine } );

	// testing, doesn't work
	//	$container.css("background", "green");
	//	$container.bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function(){
	//		$container.css("background", "red");
	//	});


}
// ------------------------------


// ------------------------------
// CHANGE PAGE
function setActivePage() {

		$('.page').removeClass('active').hide();
		var path = $.address.path();
		path = path.slice(1, path.length);
		path = giveDetailUrl() != -1 ? portfolioKeyword: path;

        // ga('send', 'pageview', path ); // google send pageview

		if(path == "") {  // if hash tag doesnt exists - go to first page
			var firstPage = $('#header ul li').first().find('a').attr('href');
			path = firstPage.slice(2,firstPage.length); // == could be an issue
			$.address.path(path);
			}

		if(Modernizr.csstransforms && Modernizr.csstransforms3d) { // modern browser
			$('#'+ path).show().removeClass('animated ').addClass('animated ' + safeModPageInAnimation);
		} else { //old browser
			$('#'+ path).fadeIn();
			$('.page.active').hide();
		}

		$('#'+ path).addClass('active');

		// detect if user is on cover page
		if($('.page.active').find('.cover').length) {
			$('html').removeClass('not-on-cover-page').addClass('on-cover-page');
		} else {
			$('html').removeClass('on-cover-page').addClass('not-on-cover-page');
		}

		setCurrentMenuItem();

		if (path.indexOf(portfolioKeyword) != -1) {
			setTimeout(function() { setMasonry(); }, 100);
		}

		// fitText();

}
// ------------------------------


// ------------------------------
// PROGRESS BARS
function animateBars() {
	$('.bar').each(function() {
		 var bar = $(this);
		 bar.find('.progress').css('width', bar.attr('data-percent') + '%' );
		});
}
// ------------------------------



// ------------------------------
// FIT TEXT
// function fitText() {
// 	$(".cover h1").fitText(cover_h1_tune);
// 	$(".cover h2").fitText(cover_h2_tune);
// 	$(".cover h3").fitText(cover_h3_tune);
// 	$(".cover h3 span").fitText(cover_h3_span_tune);
// }
// ------------------------------


// ------------------------------
// LIGHTBOX Function was previously here
// now in proper .js because of portofolio




// ------------------------------
// SCROLLBARS
var scroller = [];

// SETUP SCROLLBARS
function setupScrollBars() {
	if(!safeMod) { // don't run antiscroll if safe mode is on

		$('.antiscroll-wrap').each(function(index, element) {
			scroller[index] = $(this).antiscroll( { x: false, autoHide: $('html').attr('data-autoHideScrollbar') != 'false' }).data('antiscroll');
		});

	}
}

// REFRESH SCROLLBARS
function refreshScrollBars() {
	 setTimeout(function() { rebuildScrollBars(); setupScrollBars(); }, 500);
}

// REBULD SCROLLBARS
function rebuildScrollBars() {
	 $.each( scroller, function(i, l){
		scroller[i].rebuild();
	 });
}
// ------------------------------


// ------------------------------
// SET CURRENT MENU ITEM
function setCurrentMenuItem() {
	var activePageId = $('.page.active').attr('id');
	// set default nav menu
	$('#header nav ul a[href$=' + activePageId +']').parent().addClass('current_page_item').siblings().removeClass('current_page_item');
}
// ------------------------------



// ------------------------------
// iOS Overscroll fix
// ------------------------------

// Helper function
function push_page_edges () {

    // we can't pass jQuery objects $(".p-overlay") directly (probably because it's a function), so we have to construct it
    var container = $(".p-overlay");
    var container_scrollTop = container.scrollTop();
    // console.log("iOS Fix called === ");

    // push down 1px
    if ( container_scrollTop <= 0 ) {
        container.scrollTop( 1 );
        // console.log( "pushed down" );
    }

    // push up 1px
    else if ( container_scrollTop >= content_max_scrollTop ) {
        container.scrollTop( container_scrollTop-1 );
        // console.log( "pushed up" );
    }

};

var content_max_scrollTop = 0;

function iOS_Elastic_Scroll_Fix ( ) {

    function Page_Bottom_Position () {
        var content_height = $(".portfolio-single").innerHeight();
        var container_height = $(".p-overlay").height();
        var scrollTop_at_pageBottom = content_height - container_height;
        // console.log("portfolio-single innerHeight: (content) " + content_height );
        // console.log("p height (container) : " + container_height );
        // console.log("page scroll bottom: " + scrollTop_at_pageBottom );
        return Math.max ( scrollTop_at_pageBottom, 0 ); // make sure not below 0
    };

    content_max_scrollTop = Page_Bottom_Position();

    $("body").on("touchstart", ".p-overlay",
        push_page_edges
    );

    $( window ).on( "resize orientationchange", function( event ) {
        content_max_scrollTop = Page_Bottom_Position();
	});

};

function iOS_Elastic_Scroll_Fix_deregister ( ) {
    $("body").off("touchstart", ".p-overlay",
        push_page_edges
    );
};



// ------------------------------
// AJAX PORTFOLIO DETAILS
var pActive;

function showProjectDetails(url) {

	// showLoader();

	var p = $('.p-overlay:not(.active)').first();
	pActive = $('.p-overlay.active');

	if(pActive.length) {
		hideProjectDetails();
	}

	// ajax: fill data
	// p.empty().load($.address.baseURL() +  '/' + url + ' .portfolio-single', function() {

        p.empty();
        p.show(); // hide the element because else tearing when scrolling on iOS
        // Filling in data from Films_List
        // url contains href from index.html (which have class="ajax")
        var _Film_Data = Generate_Portfolio_Item(url);
        p.append(_Film_Data);
        // set correct res for first image

        // get first image
        var First_Image = $(".portfolio-single .swiper-slide:first img");

        // Pace Nanoloader
        // - Pace checks whether an element exists as DOM, not if it is loaded !
        Pace.options.elements.selectors = ["body", ".portfolio-single .swiper-slide.loaded"];
        Pace.restart();
        // console.log(First_Image);

		// responsive videos
		// $(".portfolio-single").fitVids();

		// need to be initialized for ajax loaded content as jquery.ready doesn't fire for ajax call
		// setupLightbox(); // needed for videos "Watch Trailer"



        // Portfolio Slide Up Function
        var portfolio_shown = false;

        function Portfolio_show () {

            if ( portfolio_shown === false ) {

                portfolio_shown = true;

                // zooms out magazine book by chaning perspective
    	        $( '#rm-container' ).addClass( 'rm-in rm-nodelay' );

        		// scroll to top with animation
        		// $('html, body').animate({  scrollTop: 0	 }, 750);

    			// setTimeout(function() {
    			// 	$( '.main' ).css("display", "none"); // hide main content, else page height to high note: .hide() is buggy
    			// }, 750);

                // show portfolio
    			$('html').addClass('p-overlay-on');

    			if (Modernizr.csstransforms && Modernizr.csstransforms3d) { // modern browser
        			p.removeClass('animated '+ outAnimation + " " + inAnimation ).addClass('animated '+ inAnimation).show();
    			}
                else
                { //old browser
    				p.fadeIn();
    			};

                initSwiper();

        		p.scrollTop(0); // in case p overlay has not been previously scrolled all up by user

    			p.addClass('active');

                $(".loading_item_indicator").removeClass("loading_item_indicator");

                // iOS scroll overlay fix: prevent underlying body from scrolling
                // idea: always keep a puffer of 1px at the top and bottom, as iOS starts to scoll the page body when at the end of an overlay
                iOS_Elastic_Scroll_Fix();


                // ===================
                // Change Page meta tags
                // ===================

                // Page title
                var Page_portfolio_title = "Nilton Martins in " + Film_List[url].title;
                // new desc
                var Page_Desc = Film_List[url].quote;
                if ( Page_Desc === undefined ) {
                    Page_Desc = Film_List[url].synopsis ;
                }
                Page_Desc = Page_Desc.toString().replace(/(<([^>]+)>)/ig,""); // remove HTML <tags>

                // Keywords extraction with undefined control
                var cast = Film_List[url].actors;
                if ( cast !== undefined ) {
                    cast = cast.toString() + ", ";
                }
                else {
                    cast = "";
                }
                var director = Film_List[url].director;
                if ( ( director !== undefined ) && ( director.length>1 ) ) {
                    director = director + ", ";
                }
                else {
                    director = "";
                }
                // Keywords assembled
                var Page_Keywords =
                    Film_List[url].title + ", " +
                    cast +
                    director +
                    Film_List[url].production_company + ", " +
                    Page_Keywords_ORG;

                // og - open graph
                var Page_url = window.location.href;
                // IE below 11 fix
                // if (!window.location.origin) {
                //   window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
                // }
                // get niltonmartins.com or the current domain (if on testing server)
                // not working because of hotlink leech protection and origin doesn't have the base url with /Nilton in it
                // var domain_url = window.location.origin;

                Page_meta_tags_change(
                    Page_portfolio_title,
                    Page_Desc,
                    Page_Keywords,
                    Page_portfolio_title,
                    Page_Desc,
                    Page_url,
                    ['<meta property="og:image" content="/images/Portfolio_Pics/w_1400/' + url + '_1.jpg" />']
                );

                // ===================

            };

        };

        // Show Portfolio either when first image is ready, or after 2secs the latest

		First_Image.imagesLoaded(function() {
            // wait for first image to be loaded (so that we don't present an empty overlay)
            Portfolio_show ();
		});

		setTimeout(function() {
            Portfolio_show ();
		}, 2000);

	// });
}


function hideProjectDetails(forever, safeClose) {

	//$("body").scrollTop(0);

	$( '.main' ).show(); // show main content again

	// otherwise isotope items are all messed up after "portfolio" view resize
	// setMasonry();

	// close completely by back link.
	if(forever) {
		pActive = $('.p-overlay.active');

        Pace.stop();
        $("body").removeClass("Pace_z_index_in_Front");

        // remove "Watch Trailer" Videoclip if still loading in iframe
        // so it doesn't pop up later on the normal page
        $("html").removeClass("Show_iFrame_Lightbox");
        $(".Movie_Lightbox_Container").empty();


		$('html').removeClass('p-overlay-on');
		$( '#rm-container' ).removeClass( 'rm-in' );

		if(!safeClose) {
			// remove detail url
			$.address.path(portfolioKeyword);
		}
	}

	// trigers css repaint on quote element, else the arrow is white after clicking close in a portfolio-item overlay
    // $(".praise .block-text").css("font-size", "100%");
    // seems to work again now without repaint

	pActive.removeClass('active');
    $(".loading_item_indicator").removeClass("loading_item_indicator");

	if(Modernizr.csstransforms && Modernizr.csstransforms3d) { // modern browser
		pActive.removeClass('animated '+ inAnimation).addClass('animated '+ outAnimation);
		setTimeout(function() {
			// pActive.hide().removeClass(outAnimation);
            pActive.removeClass(outAnimation);
            pActive.hide();
		} ,750) // was 1010
	} else { //old browser
		pActive.fadeOut();
	}

    iOS_Elastic_Scroll_Fix_deregister();

    Page_meta_tags_reset_original();

}


// ------------------------------

function giveDetailUrl() {

	var address = $.address.value();
	var detailUrl;

	if (address.indexOf("/"+ portfolioKeyword + "/")!=-1 && address.length > portfolioKeyword.length + 2 ) {
		var total = address.length;
		detailUrl = address.slice(portfolioKeyword.length+2,total);
	} else {
		detailUrl = -1;
	}
	return detailUrl;
}

// ------------------------------
// AJAX LOADER
function showLoader() {
	$(".loader").fadeIn(500);
}
function hideLoader() {
	$(".loader").fadeOut(250);
}
// ------------------------------



// ------------------------------
// BOOK LAYOUT
var Menu = (function() {

	var $container = $( '#rm-container' ),
		$cover = $container.find( '.rm-cover .cover' ),
		$middle = $container.find( 'div.rm-middle' ),
		$right = $container.find( 'div.rm-right' ),
		$open = $cover.find('a.rm-button-open'),
		$close = $right.find('.rm-close');

		var init = function() {

			initEvents();

		},
		initEvents = function() {
			// $open.on( 'click', function( event ) {
			$cover.on( 'click', function( event ) {
				if(!safeMod) {

					openMenu();
                    // if active return false will prevent links in "resume" to work (book mode)
					// return false;
				}

			} );

			$close.on( 'click', function( event ) {

				closeMenu();
				return false;

			} );

		},
		openMenu = function() {
            if ( Firefox_IE_Browser ) {
                // preseve-3d on the book flips all desendant's z-indexes for Book and woin't allow clicks, therefore if Browser flips them, we are just hidding the inside content and add it on open
                $("html").removeClass( 'Open_Book_z_index_BugFix' );
            }
            $container.removeClass('rm-closed');
			setTimeout(function() { $container.addClass( 'rm-open' ); },10);
            setTimeout(function() { $container.addClass( 'rm-bugfix-pages-gap' ); },550);
		},
		closeMenu = function() {

			$container.removeClass( 'rm-open rm-nodelay rm-in' );
			setTimeout(function() { $container.addClass( 'rm-closed' ) }, 850 );
            setTimeout(function() { $container.removeClass( 'rm-bugfix-pages-gap' ); }, 200 );

            if ( Firefox_IE_Browser ) {
                // preseve-3d on the book flips all desendant's z-indexes for Book and woin't allow clicks, therefore if Browser flips them, we are just hidding the inside content and add it on open
                setTimeout(function() { $("html").addClass( 'Open_Book_z_index_BugFix' ) }, 200 );
            }

		};

	return { init: init };

})();
// ------------------------------

	// filter items when filter link is clicked
    $(".phone").click( function(e) {
        e.preventDefault();
        var response = confirm("Do you want to call now?");
        if ( response === true ) {
            // window.open("tel:+352-621-653-358");
            $('<iframe src="tel:+352-621-653-358">').appendTo('body').css("display", "none");
        }
    });
